<template>
  <DsPageHeader :screen="screen" :theme="headerTheme">
    <template #logo="{ theme }">
      <DsHeaderLogoLink
        :theme="theme"
        :url="homePath"
        :label="logoLabel"
        :aria-label="labels.ariaLabelLogo"
        :nuxt="logoAsNuxtLink"
      />
    </template>
    <template #navigation="{ theme }">
      <DsHeaderNavigationMultiscreen
        v-if="hideLoading"
        :theme="theme"
        :screen="screen"
        :primary-items="navigationItems.primaryItems"
        :secondary-items="navigationItems.secondaryItems"
        :cta-item="navigationItems.ctaItem"
        :labels="labels"
      >
        <template v-if="!hideSearch" #search>
          // TODO migrate
          <!-- <Search :screen="screen" /> -->
        </template>
      </DsHeaderNavigationMultiscreen>
      <DsHeaderNavigation
        v-else
        :theme="theme"
        :screen="screen"
        :primary-items="navigationItems.primaryItems"
        :secondary-items="navigationItems.secondaryItems"
        :cta-item="navigationItems.ctaItem"
        :labels="labels"
      >
        <template v-if="!hideSearch" #search>
          // TODO migrate
          <!-- <Search :screen="screen" /> -->
        </template>
      </DsHeaderNavigation>
    </template>
  </DsPageHeader>
</template>

<script setup>
import {
  DsHeaderLogoLink,
  DsHeaderNavigation,
  DsHeaderNavigationMultiscreen,
  DsPageHeader
} from 'awds'

const props = defineProps({
  headerTheme: {
    type: String,
    required: true
  },
  logoLabel: {
    type: String,
    default: ''
  },
  navigationData: {
    type: Object,
    default: null
  },
  hideSearch: {
    type: Boolean,
    default: false
  },
  logoAsNuxtLink: {
    type: Boolean,
    default: false
  },
  hideLoading: {
    type: Boolean,
    default: false
  }
})

const { locale } = useI18n()
const { defaultLocale } = useLocales()
const { labels: globalLabels } = useGlobalData()
const { screen } = useScreen()

const labels = computed(() => {
  return {
    ariaLabelLogo: globalLabels?.brandLogo || '',
    ariaLabelNavigation: globalLabels?.ariaLabelNavigation || '',
    ariaLabelOpenNavigation: `${globalLabels?.open} ${globalLabels?.ariaLabelNavigation}` || '',
    ariaLabelCloseNavigation: `${globalLabels?.close} ${globalLabels?.ariaLabelNavigation}` || '',
    searchLabel: globalLabels?.search || ''
  }
})

const navigationItems = computed(() => {
  return props.navigationData || { primaryItems: [], secondaryItems: [], ctaItem: {} }
})

const homePath = computed(() => {
  // @TODO [POST_MIGRATION] - replace it with `localePath('/')` function and render a nuxt-link
  return (locale.value === defaultLocale) ? '/' : `/${locale.value}`
})

</script>
